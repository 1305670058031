<template>
  <section>
    <fetch-loading v-if="pending" />
    <fetch-error v-else-if="error" :error="error" />
    <div v-else class="root">
      <breadcrumbs :items="breadcrumbItems" :colour="sharedDetails.color" />

      <section v-for="(c, i) in components" :key="`section_${i}`">
        <content-section
          v-if="c?.containerProps"
          v-bind="c?.containerProps"
          :heading-component="i === 0 ? 'h1' : 'h2'"
        >
          <template v-if="c?.lead" #lead>
            <div v-html="$md.render(c?.lead)" />
          </template>
          <component :is="componentCollection[c.type]" v-bind="c?.props" />
        </content-section>
        <component v-else :is="componentCollection[c.type]" v-bind="c?.props" />
      </section>

      <div v-if="isDev" class="fixed left-0 top-32 bottom-0 w-[33vw] bg-white border border-gray-300 transform transition-all duration-200" :class="viewDebugData ? 'left-0' : '-left-[33vw]'">
        <div class="absolute bottom-5 -right-7 w-7 bg-brand-blue-100 border border-brand-blue-200 border-l-0 hover:bg-brand-blue-200" @click="viewDebugData = !viewDebugData">
          <Icon name="IconChevronRight" class="text-2xl ml-auto align-middle py-3 transform transition-all duration-200" :class="viewDebugData ? 'rotate-180' : ''" />
        </div>
        <div class="overflow-y-scroll h-full">
          <div class="grid grid-cols-1">
            <div class="sample-data">
              <h2>Route Data</h2>
              <div class="grid grid-cols-2 gap-2">
                <pre><strong>baseUrl:</strong> {{ baseUrl }}</pre>
                <pre><strong>basePath:</strong> {{ basePath }}</pre>
                <pre><strong>slug:</strong> {{ $route.params?.slug }}</pre>
                <pre><strong>subPaths:</strong> {{ subPaths }}</pre>
                <pre class="col-span-2"><strong>currentPath:</strong> {{ currentPath }}</pre>
                <pre><strong>breadcrumbItems:</strong> {{ breadcrumbItems }}</pre>
              </div>
            </div>
            <div v-if="unmappedComponents.length > 0" class="sample-data">
              <div class="flex flex-row" @click="debugView.unmapped = !debugView.unmapped">
                <h2>Unmapped Components</h2>
                <Icon name="IconChevronDown" class="text-xl ml-auto transform transition-all duration-200" :class="debugView.unmapped ? 'rotate-180' : ''" />
              </div>
              <pre :class="debugView.unmapped ? '' : 'hidden'">{{ unmappedComponents }}</pre>
            </div>
            <div v-if="mappedComponents.length > 0" class="sample-data">
              <div class="flex flex-row" @click="debugView.mapped = !debugView.mapped">
                <h2>Mapped Components</h2>
                <Icon name="IconChevronDown" class="text-xl ml-auto transform transition-all duration-200" :class="debugView.mapped ? 'rotate-180' : ''" />
              </div>
              <pre :class="debugView.mapped ? '' : 'hidden'">{{ mappedComponents }}</pre>
            </div>
            <div class="sample-data">
              <div class="flex flex-row" @click="debugView.dataset = !debugView.dataset">
                <h2>Dataset</h2>
                <Icon name="IconChevronDown" class="text-xl ml-auto transform transition-all duration-200" :class="debugView.dataset ? 'rotate-180' : ''" />
              </div>
              <pre :class="debugView.dataset ? '' : 'hidden'">{{ dataSet }}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useSitecoreLayoutPageStore } from '~/stores/sitecoreLayoutPage'
import { isEmpty } from 'lodash-es'
const { stringEmpty } = useHelpers()
const { structuredData } = useStructuredData()

import LayoutDataSet = models.stores.sitecoreLayoutPage.LayoutDataSet

const app = useNuxtApp()

interface HeroBannerComponent {
  props?: {
    image?: {
      src?: string,
      alt?: string
    }
  }
}

const route = useRoute()

// data
const componentCollection: Record<string, unknown> = {
  Accordions: resolveComponent('Accordions'),
  ContentArea: resolveComponent('ContentArea'),
  ContentCards: resolveComponent('ContentCards'),
  HeroBanner: resolveComponent('HeroBanner'),
  Table: resolveComponent('Table'),
  TestimonialCards: resolveComponent('TestimonialCards'),
  Video: resolveComponent('VideoSection'),
  PartnerLogos: resolveComponent('LatestPartnersList')
}
const viewDebugData = ref(false)
const debugView = reactive({
  unmapped: false,
  mapped: false,
  dataset: false
})

const baseUrl = computed(() => (route.meta?.baseUrl as string) || undefined)

const basePath = computed(() => {
  if (!baseUrl.value) return undefined

  return baseUrl.value?.replace(/^\//, '').split('-').join(' ')
})

const subPaths = computed(() => Array.isArray(route.params?.slug)
    ? (route.params?.slug as string[]).filter(Boolean)
    : [])

const currentPath = computed(() => {
  return [basePath.value, ...subPaths.value].filter(Boolean).join('/')
})

const { pending, error, data: dataSet } = await useAsyncData(async () => {
  if (stringEmpty(currentPath.value)) {
    throw '404 not found'
  }

  const { fetch } = useSitecoreLayoutPageStore()
  const dataSet: Partial<LayoutDataSet> = (await fetch(currentPath.value)) || {}

  if (isEmpty(dataSet)) {
    throw '404 not found'
  }

  return dataSet
})

const sharedDetails = computed(() => dataSet.value?.shared || {})
const components = computed(() => dataSet.value?.components || [])
const unmappedComponents = computed(() => (dataSet.value?.components?.filter((c) => !componentCollection[c.type])) || [])
const mappedComponents = computed(() => (dataSet.value?.components?.filter((c) => !!componentCollection[c.type])) || [])

const breadcrumbItems = computed(() => {
  const paths = dataSet.value?.page?.itemPath?.split('/').filter(Boolean) || []

  if (paths.length > 0) {
    const routes = [baseUrl.value, ...subPaths.value]
    return paths.map((p, i) => ({
      text: deslugify(p),
      to: `${routes.slice(0, i + 1).join('/')}`
    }))
  } else {
    // keep original method in case itemPath field is empty
    const base = [
      {
        text: sentenceCase(basePath.value),
        to: baseUrl.value
      }
    ]

    for (const p of subPaths.value) {
      base.push({
        text: sentenceCase(p),
        to: `${base[base.length - 1].to}/${p}`
      })
    }

    return base
  }
})

app.runWithContext(() => {
  // combining metadata
  const metadata = computed(() => dataSet.value?.page?.metadata || {})
  const title = `${metadata.value?.title || 'Choose from hundreds of courses. Explore. Enquire. Enrol.'}`

  const socialPreviewImageUrl = computed(() => {
    const heroBannerComponent: HeroBannerComponent | undefined = mappedComponents.value.find(c => c.type === 'HeroBanner')
    return heroBannerComponent?.props?.image?.src || ''
  })

  useHead({
    title: `$ | ��ɫ��Ƶ`,
    script: [
      {
        type: 'application/ld+json',
        innerHTML: JSON.stringify(structuredData.value)
      }
    ],
    meta: [
      {
        name: 'description',
        content: metadata.value?.description
      },
      {
        name: 'keywords',
        content: metadata.value?.keywords
      },
      {
        property: 'og:image',
        content: socialPreviewImageUrl.value
      },
      {
        property: 'og:image:secure_url',
        content: socialPreviewImageUrl.value
      },
      {
        property: 'twitter:card',
        content: 'summary_large_image'
      }
    ]
  })
})

// dev mode
const isDev = computed(() => {
  const { public: config } = useRuntimeConfig()
  return (
    route.query?.debug &&
    route.query?.debug === 'true' &&
    ['local', 'dev', 'test', 'preprod'].includes(config.appEnv)
  )
})

// helpers
const sentenceCase = (val?: string) => {
  if (stringEmpty(val)) return ''

  let text = deslugify(val)
  const matchers = ['tafe', 'nsw']
  matchers.forEach((m) => {
    text = text.replace(new RegExp(`\\b${m}\\b`, 'gi'), m.toUpperCase())
  })

  return text[0].toUpperCase() + text.slice(1)
}

// TODO: eslint complains about lookbehind assertions, ecmaVersion is correct but still complains
const deslugify = (val?: string) =>
  stringEmpty(val) ? '' : (<string>val).replace(/(?<=\w)-(?=\w)/gi, ' ').trim()
</script>

<style lang="postcss" scoped>
div.root > section:nth-of-type(even) {
  @apply bg-gray-100;
}

.sample-data {
  @apply overflow-x-auto px-2;

  div {
    @apply py-3;
  }
}

.sample-data:nth-of-type(odd) {
  @apply bg-gray-200;
}
</style>
